import React, { useState, useEffect } from "react"

import { TimelineLite, Power2 } from "gsap"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import CloseIcon from "../../images/close-icon.svg"
import { Menu } from "../Menu"

import { StyledHeader, StyledLogo, StyledMenuButton } from "./style"

const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false)

  useEffect(() => {
    let tl = new TimelineLite()

    tl.from(".header-logo, .menu-trigger", 1, {
      y: -10,
      opacity: 0,
      ease: Power2.easeOut,
      stagger: {
        amount: 0.4,
      },
    })
  }, [])

  return (
    <>
      <StyledHeader isMenuOpen={isMenuOpen}>
        <StyledLogo
          cover
          bg="#fff"
          direction="top"
          duration={2}
          to="/"
          className="header-logo"
          isMenuOpen={isMenuOpen}
        >
          Carola <br />
          Allemandi
        </StyledLogo>
        <StyledMenuButton type="button" role="button" isMenuOpen={isMenuOpen}>
          <span
            className="menu-trigger menu-trigger--open"
            onClick={() => setMenuOpen(true)}
          >
            Menu
          </span>
          <span
            className="menu-trigger menu-trigger--close"
            onClick={() => setMenuOpen(false)}
          >
            <CloseIcon />
          </span>
        </StyledMenuButton>
      </StyledHeader>
      <Menu isMenuOpen={isMenuOpen} />
    </>
  )
}

export default Header
