import styled from "styled-components"

export const StyledNav = styled.nav`
  width: 100%;
  height: 100%;
  position: fixed;
  /* top: 120px; */
  left: 0;
  z-index: 10;
  padding-top: 120px;
  background-color: #fff;
  transform: ${({ isMenuOpen }) =>
    isMenuOpen ? `translateY(0)` : `translateY(-999px)`};
  transition: transform 1s cubic-bezier(0.4, 0.01, 0.165, 0.99);


  /* mix-blend-mode: exclusion; */
  pointer-events: ${({ isMenuOpen }) => (isMenuOpen ? "auto" : "none")};

  @media (min-width: 1024px) {
    width: auto;
    height: auto;
    display: block;
    position: fixed;
    top: calc(80px / 2);
    right: 5rem;
    left: unset;
    z-index: 20;
    margin-top: 0;
    padding-top: 0;
    transform: translateY(0);
    pointer-events: auto;
  }

  .nav-list {
    width: 100%;
    max-height: ${({ isMenuOpen }) => (isMenuOpen ? `100%` : `0`)};
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
    padding: 0 1rem;
    transition: opacity .5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
      /* transform 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99); */
    /* transform: ${({ isMenuOpen }) =>
      isMenuOpen ? `translateY(0)` : `translateY(-1000px)`}; */

    opacity: ${({ isMenuOpen }) => (isMenuOpen ? 1 : 0)};

    pointer-events: ${({ isMenuOpen }) => (isMenuOpen ? "auto" : "none")};

    @media (min-width: 1024px) {
      width: auto;
      height: auto;
      display: block;
      padding: unset;
      margin-top: 0;
      opacity: 1;
      pointer-events: auto;
      transform: translateY(0);
    }

    &__title {
      margin-bottom: 2rem;
      font-weight: 400;
      letter-spacing: 1px;

      @media (min-width: 1024px) {
        display: none;
      }
    }
  }

  .nav-list__item {
    width: 100%;
    display: inline-block;
    position: relative;
    padding: 1.5rem 0;

    color: #a8a7a7;
    font-size: 20px;
    text-decoration: none;

    border-bottom: 1px solid transparent;
    transition: color 200ms ease-in-out;
    -webkit-tap-highlight-color: transparent;
    z-index: 20;

    @media (min-width: 1024px) {
      width: auto;
      padding: 0;
      /* mix-blend-mode: exclusion; */

      &:not(:last-of-type) {
        margin: 0;
        font-size: 14px;
      }

      &:not(:nth-last-of-type(-n + 2)) {
        margin-right: 2rem;
      }

      &:before {
        display: none;
      }
    }

    svg {
      width: 18px;
      height: 18px;
      margin-right: 1rem;
      fill: #828282;

      @media (min-width: 1024px) {
        display: none;
      }
    }

    &:hover,
    &--active {
      /* border-bottom: 1px solid #fff; */
      border-bottom: 1px solid #000;
      color: #000;

      @media (min-width: 1024px) {
        /* color: #fff; */
        color: #000;
      }

      svg {
        fill: #000;
      }
    }

    @media (min-width: 768px) {
      &:hover {
        border-bottom: 1px solid #fff;
      }
    }

    &--info {
      @media (min-width: 1024px) {
        display: none;
      }
    }
  }
`

// export const StyledMenuLayer = styled.div`
//   width: 100%;
//   height: 100vh;
//   background-color: #000;
//   position: fixed;
//   top: 0;
//   left: 0;
//   transform: ${({ isMenuOpen }) =>
//     isMenuOpen ? `translateY(0)` : `translateY(-100%)`};
//   transition: transform 0.8s cubic-bezier(0.4, 0.01, 0.165, 0.99);
//   z-index: 1000;
//   border: 1px solid red;

//   @media (min-width: 1024px) {
//     display: none;
//   }
// `
