import React, { useEffect } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql, Link } from "gatsby"
import { createGlobalStyle } from "styled-components"
import { TimelineLite, Power2 } from "gsap"
import styled from "styled-components"
import AniLink from "gatsby-plugin-transition-link/AniLink"

// import ArrowDown from "../images/arrow-down.svg"
import ChevronDown from "../images/chevron-down.svg"
import { Header } from "../components"

const GlobalStyle = createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }

  *:before,
  *:after {
    box-sizing: border-box;
  }

  html {
    width: 100%;
    height: 100%;
    font-size: 100%;
    background: #fff;
    scroll-behavior: smooth;
  }

  body {
    width: 100%;
    height: 100%;
    font-size: 16px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    /* background: #fff; */
    overflow: ${({ isScrollable }) => (isScrollable ? "scroll" : "hidden")};
  }

  .page-wrapper {
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;

    @media (min-width: 1024px) {
      margin: 0 auto;
      padding-right: 0;
      padding-left: 0;
    }
  }

  main {
    width: 100%;
    min-height: 80vh;
    /* flex: 1 0 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: relative; */

    @media (min-width: 2500px) {
      width: 80%;
      margin: 0 auto;
    }
  }

  a {
    color: inherit;
  }

  .wrapper {
    margin: 0 auto;

    @media (min-width: 1024px) {
      width: calc(100% - 10rem);
    }
  }

`
const StyledFooter = styled.footer`
  width: 100%;
  height: 130px;
  display: none;
  position: fixed;
  bottom: 0;

  mix-blend-mode: difference;

  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 20;
    padding: 0 5rem;

    .info-link {
      /* position: fixed; */
      /* left: 5rem; */
      bottom: calc(100px / 2);
      border-bottom: 1px solid transparent;
      color: #828282;
      text-decoration: none;
      mix-blend-mode: difference;

      &:hover,
      &--active {
        mix-blend-mode: normal;
        border-bottom: 1px solid #fff;
        color: #000;

        svg {
          fill: #fff;
        }
      }
    }

    .scroll-indicator {
      /* color: #fff; */
      color: #000;
      /* position: fixed;
      right: 3.5rem;
      bottom: calc(100px / 2); */
      /* mix-blend-mode: difference; */

      span {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        mix-blend-mode: difference;
      }

      svg,
      path {
        /* fill: #fff; */
        fill: #000;
        position: relative;
        top: 2px;
      }
    }

    .copyright {
      color: #dddddd;
      font-size: 0.95rem;
    }
  }
`

const Layout = ({ children, isScrollable, isCarousel }) => {
  const isBrowser = typeof window !== "undefined" && window
  const carouselClass = isCarousel ? "swiper-container" : ""

  const data = useStaticQuery(graphql`
    query LayoutQuery {
      info: datoCmsInfo {
        slug
        pageTitle
      }
    }
  `)

  useEffect(() => {
    let tl = new TimelineLite()

    tl.from(".info-link, .scroll-indicator", 0.8, {
      y: 10,
      opacity: 0,
      delay: 0.5,
      ease: Power2.easeOut,
      stagger: {
        amount: 0.4,
      },
    })

    // new Swiper(".swiper-container", {
    //   loop: true,
    //   speed: 2300,
    //   // autoplay: autoplayContentSlider,
    //   mousewheel: true,
    //   effect: "fade",
    //   direction: "vertical",
    //   fadeEffect: { crossFade: true },
    //   pagination: {
    //     el: ".swiper-pagination",
    //     type: "progressbar",
    //   },
    //   navigation: {
    //     nextEl: ".slide-next",
    //     prevEl: ".slide-prev",
    //   },
    // })
  }, [])

  return (
    <>
      <GlobalStyle isScrollable={isScrollable} />
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@600&family=Poppins:wght@200,300;400&family=Source+Sans+Pro:wght@300;400;600&Courier+Prime:wght@700&display=swap"
          rel="stylesheet"
        />
        <meta name="viewport" content="initial-scale=1, maximum-scale=1" />
      </Helmet>
      <div className="page-wrapper">
        <Header />
        <main className="main">{children}</main>
        <StyledFooter>
          <AniLink
            cover
            bg="#fff"
            direction="top"
            duration={2}
            to={`/${data.info.slug}`}
            className="info-link"
            activeClassName="info-link--active"
          >
            {data.info.pageTitle}
          </AniLink>

          {/* {isBrowser && window.location.href.includes("/info") ? (
            <div className="copyright">
              <span>© All rights reserved, 2020</span>
            </div>
          ) : (
            <div className="scroll-indicator">
              <span>
                Scroll down &nbsp; &nbsp;
                <ChevronDown />
              </span>
            </div>
          )} */}

          <div className="copyright">
            <span>© All rights reserved, 2020</span>
          </div>
        </StyledFooter>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isScrollable: false,
}

export default Layout
