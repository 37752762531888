import React, { useEffect } from "react"
import { TimelineLite, Power2 } from "gsap"
import { useStaticQuery, graphql, Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import ArrowDiagonal from "../../images/arrow-diagonal.svg"

import { StyledNav, StyledMenuLayer } from "./style"

const Menu = ({ isMenuOpen }) => {
  useEffect(() => {
    let tl = new TimelineLite()

    tl.from(".nav-list__item", 0.8, {
      y: -10,
      opacity: 0,
      delay: 0.5,
      stagger: {
        amount: 0.4,
      },
    })
  }, [])

  useEffect(() => {
    let tl = new TimelineLite()

    if (isMenuOpen) {
      tl.from(".nav-list__item", 1.2, {
        y: -7,
        opacity: 0,
        delay: 0.8,
        stagger: {
          amount: 0.4,
        },
      })
    }
  }, [isMenuOpen])

  const data = useStaticQuery(graphql`
    query MenuQuery {
      homepage: datoCmsHomepage {
        pageTitle
      }
      pages: allDatoCmsPagesCollection(
        sort: { fields: [position], order: ASC }
      ) {
        edges {
          node {
            slug
            pageTitle
          }
        }
      }
      info: datoCmsInfo {
        slug
        pageTitle
      }
    }
  `)

  const { edges } = data.pages
  const allMenus = [...edges, data.homepage, data.info]

  return (
    <>
      {/* <StyledMenuLayer
        isMenuOpen={isMenuOpen}
        className="menu-secondary-background"
      ></StyledMenuLayer> */}
      <StyledNav isMenuOpen={isMenuOpen} allMenus={allMenus}>
        <ul className="nav-list">
          <AniLink
            cover
            bg="#fff"
            direction="top"
            duration={2}
            to="/"
            className="nav-list__item"
            activeClassName="nav-list__item--active"
          >
            <ArrowDiagonal />
            {data.homepage.pageTitle}
          </AniLink>
          {edges.map(({ node }, i) => (
            <AniLink
              cover
              bg="#fff"
              direction="top"
              duration={2}
              to={`/${node.slug}`}
              className="nav-list__item"
              activeClassName="nav-list__item--active"
            >
              <ArrowDiagonal />
              {node.pageTitle}
            </AniLink>
          ))}

          <AniLink
            cover
            bg="#fff"
            direction="top"
            duration={2}
            to={`/${data.info.slug}`}
            className="nav-list__item nav-list__item--info"
            activeClassName="nav-list__item--active"
          >
            <ArrowDiagonal />
            {data.info.pageTitle}
          </AniLink>
        </ul>
      </StyledNav>
    </>
  )
}

export default Menu
