import styled from "styled-components"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export const StyledHeader = styled.header`
  width: 100%;
  height: 120px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  margin: 0 auto;
  padding: 0 1rem;
  background: transparent;
  /* mix-blend-mode: ${({ isMenuOpen }) =>
    isMenuOpen ? "normal" : "exclusion"}; */
  transition: all 0.6s ease-in-out;
  /* mix-blend-mode: exclusion; */

  @media (min-width: 1024px) {
    padding: 0 4.5rem;
  }

  @media (min-width: 1024px) {
    backface-visibility: hidden;
  }
`

export const StyledLogo = styled(AniLink)`
  /* position: fixed;
  left: 1rem;
  top: calc(80px / 2); */
  /* background: white; */

  font-family: "Courier Prime", monospace;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 1px;
  text-decoration: none;
  color: ${({ isMenuOpen }) => (isMenuOpen ? "#fff" : "#000")};
  transition: color 0.8s ease-in;
  z-index: 20;
  /* mix-blend-mode: exclusion; */
  color: #000;

  @media (min-width: 1024px) {
    /* color: #fff; */
    color: #000;
    left: 5rem;
  }
`

export const StyledMenuButton = styled.button`
  border: none;

  font-size: 14px;
  background-color: transparent;

  outline: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;

  &:active {
    background-color: transparent;
  }

  @media (min-width: 1024px) {
    display: none;
  }

  .menu-trigger {
    &--open {
      display: ${({ isMenuOpen }) => (isMenuOpen ? "none" : "block")};
    }

    &--close {
      display: ${({ isMenuOpen }) => (isMenuOpen ? "block" : "none")};

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
`
